<template>
  <div class="select-suggestion">
      <slot name="label">
        <div
          v-if="label"
          :class="`label-div text-sm ${focused ? 'text-primary' : ''}`
        ">
          <label class="p-1">{{ label || $t(`suggestable_models.${model}` )}}</label>
        </div>
      </slot>
      <div class="flex flex-row gap-2 w-full">
        <div class="min-h-full w-full relative">
          <vs-select
            ref="select"
            :class="['w-full', selectClass]"
            autocomplete
            v-model="selectedModelId"
            @input-change="searchSuggestions"
            :placeholder="translatedPlaceholderText"
            :danger="selectedModel === undefined"
            danger-text="Seleção inválida"
            @input="selectModel"
            :noData="noData"
            @focus="onFocus"
            @change="change"
            :preventClear="preventClear"
            :disabled="isDisabled"
          >
            <vs-select-item
              :key="model.id"
              :value="model.id"
              :text="itemTextFormat(model)"
              v-for="(model) in suggestedModels"
            />
          </vs-select>

          <div class="loadingContainer absolute inset-0 pointer-events-none p-3 pr-6 flex items-center justify-end">
            <!-- <feather-icon icon="RotateCwIcon" class="animate-spin text-primary"/>
            <label class="text-primary">buscando</label> -->
          </div>
        </div>
        <vs-button
          v-if="selectedModelId && appendClearOption && !isDisabled"
          color="danger"
          class="flex-grow-0 flex-shrink-0 h-auto"
          type="border"
          icon-pack="feather"
          icon="icon-x"
          @click="clearSelection"
        ></vs-button>
      </div>
  </div>
</template>

<script>
import SuggestionService from '@/services/api/SuggestionService'
import VxInputGroup from './vx-input-group/VxInputGroup.vue'

export default {

  props: {
    selectClass: {
      type: String,
      default: ''
    },
    onlySuggestion: {
      type: Boolean,
      default: true
    },
    placeholderText: {
      type: String,
      default: ''
    },
    model: {
      type: String,
      default: ''
    },
    column: {
      type: String,
      default: null
    },
    max: {
      type: Number,
      default: 5
    },
    with: {
      type: Array,
      default: () => []
    },
    itemTextFormat: {
      type: Function,
      default: (model) => {
        return model.name || model.title || model.description || model.id
      }
    },
    selectedModel: {
      type: Object,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    noDataText: {
      type: String,
      default: function () {
        return this.$t('nenhum-valor-encontrado-informe-outro-valor-para-pesquisar')
      }
    },
    appendClearOption: {
      type: Boolean,
      default: true
    },
    color: {
      type: String,
      default: 'primary'
    },
    promptText: {
      type: String,
      default: function () {
        return this.$t('informe-um-valor-para-pesquisar')
      }
    },
    preventClear: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },

  components: {
    VxInputGroup
  },

  data: () => ({
    selectedModelId: null,
    suggestionService: null,
    suggestedModels: [],
    timeout: null,
    focused: false,
    searchInputValue: '',
    lastSuggestionCount: null
  }),

  model: {
    prop: 'selectedModel',
    event: 'selected-model'
  },

  watch: {
    max(v) {
      this.changeServiceParam('max', v)
    },
    model(v) {
      this.changeServiceParam('model', v)
    },
    column(v) {
      this.changeServiceParam('property', v)
    }
  },

  mounted() {
    this.suggestionService = SuggestionService.buildOnModel(
      this.model,
      this.column,
      this.max,
      this.with,
      this.$vs
    )
    this.guaranteeModelDisplay()
  },

  beforeUpdate() {
    this.guaranteeModelDisplay()
  },

  computed: {
    showAppendButtons() {
      return (this.selectedModelId && this.appendClearOption)
    },
    select() {
      return this.$refs['select']
    },
    searchinput() {
      return this.$refs['select'].$refs['inputselect']
    },
    noData() {

      if (!this.isEmpty(this.searchInputValue) && this.lastSuggestionCount === 0) {
        return this.noDataText
      }

      if(this.isEmpty(this.searchInputValue)){
        return this.promptText
      }

      return ''
    },
    translatedPlaceholderText() {
      const model = _.snakeCase(this.model.toString())
      return this.placeholderText || this.$t(`placeholders.select_suggestion.${model}`)
    }
  },

  methods: {
    change(event) {
      /**
       * We use this listener to watch on the underlying input event that vuesax uses.
       */
      const srcValue = _.get(event, 'srcElement.value')


      if (!this.isEmpty(srcValue)) {
        // When it isnt empty, it is when the user hast typed something
        this.$emit('input', {value: srcValue, model: this.selectedModel})
      } else if (srcValue === undefined) {
        /**
         * It is undefined when a select-option is active, so nothing is typed,
         * and the vsSelect has overtaken the underyling input's value.
         */
        this.$emit('input', {value: srcValue, model: this.selectedModel} )
      }
    },
    changeServiceParam(param, v) {
      if (this.suggestionService) this.suggestionService[param] = v
    },
    selectModel(id) {
      this.selectedModel = this.suggestedModels.find(model => model.id === id)
      this.suggestedModels = [this.selectedModel]
      this.emitSelection()
    },
    fill(model) {
      this.selectedModel = model
      this.suggestedModels = [model]
      this.selectedModelId = model.id
      this.emitSelection()
    },
    clearSelection() {
      this.$emit('clearSelection')
      this.reset()
    },
    guaranteeModelDisplay() {
      if (!this.isEmpty(this.selectedModel) && this.isEmpty(this.selectedModelId)) {
        this.selectedModelId = this.selectedModel.id
        this.suggestedModels = [this.selectedModel]
      }
    },
    fetchSuggestion(search) {
      if (search && search.length) {
        this.showLoading()
        this.suggestionService.fetchSuggestion(search).then(response => {
          this.lastSuggestionCount = response.length
          this.closeLoading()
          this.suggestedModels = response
          clearTimeout(this.timeout)
          this.timeout = null
        }, error => {
          this.closeLoading()
        })
      }
    },
    searchSuggestions() {
      if (this.timeout) clearTimeout(this.timeout)

      this.searchInputValue = this.searchinput.value // Update readonly variable.

      const search = this.searchinput.value
      if (!this.isEmpty(search)) {
        this.timeout = setTimeout(() => {
          this.fetchSuggestion(search)
        }, 500)
      } else {
        this.select.clear = false
        this.reset()
      }
    },
    emitSelection() {
      this.$emit('selected-model', this.selectedModel)
    },
    reset() {
      this.suggestedModels = []
      this.selectedModel = null
      this.selectedModelId = null
      this.searchinput.value = ''
      this.lastSuggestionCount = null
      this.emitSelection()
    },
    onFocus(event) {
      // console.log('focus on value...', this.searchinput.value)
      this.searchInputValue = this.searchinput.value // Update readonly variable.
      this.focused = true
      this.fetchSuggestion('')
    },
    showLoading() {
      const inputSelect = document.querySelector('.loadingContainer')
      if (inputSelect) {
        this.$vs.loading({
          container: inputSelect,
          scale: 0.6
        })
      }
    },
    closeLoading() {
      const inputSelect = document.querySelector('.loadingContainer')
      if (inputSelect) {
        this.$vs.loading.close(inputSelect)
      }
    }
  },


}
</script>

<style lang="scss">
  .select-suggestion {
    height: min-content;
  }
</style>
