var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "select-suggestion" },
    [
      _vm._t("label", function () {
        return [
          _vm.label
            ? _c(
                "div",
                {
                  class:
                    "label-div text-sm " + (_vm.focused ? "text-primary" : ""),
                },
                [
                  _c("label", { staticClass: "p-1" }, [
                    _vm._v(
                      _vm._s(
                        _vm.label || _vm.$t("suggestable_models." + _vm.model)
                      )
                    ),
                  ]),
                ]
              )
            : _vm._e(),
        ]
      }),
      _c(
        "div",
        { staticClass: "flex flex-row gap-2 w-full" },
        [
          _c(
            "div",
            { staticClass: "min-h-full w-full relative" },
            [
              _c(
                "vs-select",
                {
                  ref: "select",
                  class: ["w-full", _vm.selectClass],
                  attrs: {
                    autocomplete: "",
                    placeholder: _vm.translatedPlaceholderText,
                    danger: _vm.selectedModel === undefined,
                    "danger-text": "Seleção inválida",
                    noData: _vm.noData,
                    preventClear: _vm.preventClear,
                    disabled: _vm.isDisabled,
                  },
                  on: {
                    "input-change": _vm.searchSuggestions,
                    input: _vm.selectModel,
                    focus: _vm.onFocus,
                    change: _vm.change,
                  },
                  model: {
                    value: _vm.selectedModelId,
                    callback: function ($$v) {
                      _vm.selectedModelId = $$v
                    },
                    expression: "selectedModelId",
                  },
                },
                _vm._l(_vm.suggestedModels, function (model) {
                  return _c("vs-select-item", {
                    key: model.id,
                    attrs: { value: model.id, text: _vm.itemTextFormat(model) },
                  })
                }),
                1
              ),
              _c("div", {
                staticClass:
                  "loadingContainer absolute inset-0 pointer-events-none p-3 pr-6 flex items-center justify-end",
              }),
            ],
            1
          ),
          _vm.selectedModelId && _vm.appendClearOption && !_vm.isDisabled
            ? _c("vs-button", {
                staticClass: "flex-grow-0 flex-shrink-0 h-auto",
                attrs: {
                  color: "danger",
                  type: "border",
                  "icon-pack": "feather",
                  icon: "icon-x",
                },
                on: { click: _vm.clearSelection },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }